import logo from './resources/images/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
    <img className="App-logo" src={logo} alt="sneedio" />
    </div>
  );
}

export default App;
